.soundController{
    border-radius: 50%;
    position: relative;
    width: max-content;
    margin-right: 20px;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    & svg{
        cursor: pointer;
        & *{
            stroke: black;
        }
        &.colored-icon *{
            stroke: rgb(143, 3, 57);
        }
    }
    & circle, .main-box-wrp .file-item-overLay svg circle {
        fill: white !important;
    }
    & .soundController-slider{
        background-color: white;
        padding: 0 10px;
        display: none;
        border-radius: 10px;
        position: absolute;
        z-index: 1000;
        bottom: 100%;
        transform: translateX(-50%) translateY(3px);
        left: 50%;
    }
    &:hover .soundController-slider,&:focus .soundController-slider,&:focus-within .soundController-slider,.soundController-slider:hover{
        display: block;
    }
    .MuiSlider-mark{
        background-color: rgb(143, 3, 57);
    }
}


