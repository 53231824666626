.tour-360-page{
    // height: 100%;
    // width: 100%;
    position: relative;
    background-color: black;
}

.tour-360-header{
    width: 100%;
    height: 40px;
}

.dehaze-icon-color{
    color: #fff;
}

.qrCodeScanner-icon-color{
    color: #fff;
}

.tour-name-span-1{
    color: #fff;
    font-size: 14px;
}

.navigation{
    display: flex;
}

.navigate-before-icon-color{
    color: black;
}

.navigate-next-icon-color{
    color: black;
}

.navigate-before-button{
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border: 0px;
    border-radius: 50px;
    margin-left: 4%;
    margin-top: 2%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    &:hover{
        width: 170px;
        transition: 200ms;
        .previous-span{
            visibility: visible;
            padding-top: 4px;
            padding-left: 18px;
            font-size: 16px;
        }
    }
    span{
        margin-top: 7px;
        margin-left: 2px;
    }
    .previous-span{
        visibility: hidden;
        font-size: 16px;
    }
}

.navigate-next-button{
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 999em;
    border: 0px;
    margin-left: 92%;
    margin-top: 2%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    &:hover{
        width: 170px;
        transform: translateX(-120px);
        transition: 200ms;
        padding: 0px 0px 0px 10px;
        .next-span{
            width: 150px;
            border-radius: 999em;
            visibility: visible;
            font-size: 16px;
        }
    }
    .next-span{
        width: 40px;
        height: 40px;
        border-radius: 999em;
        padding-top: 11px;
        margin-left: -5px;
        visibility: hidden;
        font-size: 16px;
    }
    .navigate-next-icon-color{
        width: 40px;
        height: 40px;
        margin-left: -26px;
        margin-top: 7px;

    }
}

.fullscreen-vr-share{
    margin-left: 92%;
    margin-top: -12%;
}

.fullscreen-button-new{
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 999em;
    border: 0px;
    margin-top: 0px;
    position: fixed;
    z-index: 1;
    cursor: pointer;
    &:hover{
        width: 170px;
        transform: translateX(-130px);
        transition: 200ms;
        padding: 0px 0px 0px 10px;
        .fullscreen-span-1{
            width: 200px;
            border-radius: 999em;
            margin-left: -20px;
            visibility: visible;
            font-size: 16px;
        }
    }
    .fullscreen-span-1{
        width: 40px;
        height: 40px;
        border-radius: 999em;
        padding-top: 11px;
        visibility: hidden;
        font-size: 16px;
    }
    .fullscreen-span-2{
        width: 40px;
        height: 40px;
        margin-left: -39px;
        margin-top: 7px;

    }
}

.vr-button-new{
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 999em;
    border: 0px;
    margin-top: 46px;
    position: fixed;
    z-index: 1;
    cursor: pointer;
    &:hover{
        width: 170px;
        transform: translateX(-130px);
        transition: 200ms;
        padding: 0px 0px 0px 10px;
        .vr-span-1{
            width: 200px;
            border-radius: 999em;
            margin-left: -20px;
            visibility: visible;
            font-size: 16px;
        }
    }
    .vr-span-1{
        width: 40px;
        height: 40px;
        border-radius: 999em;
        padding-top: 11px;
        visibility: hidden;
        font-size: 16px;
    }
    .vr-span-2{
        width: 40px;
        height: 40px;
        margin-left: -40px;
        margin-top: 7px;

    }
}

.share-button-new{
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 999em;
    border: 0px;
    margin-top: 92px;
    position: fixed;
    z-index: 1;
    cursor: pointer;
    &:hover{
        width: 170px;
        transform: translateX(-130px);
        transition: 200ms;
        padding: 0px 0px 0px 10px;
        .share-span-1{
            width: 200px;
            border-radius: 999em;
            margin-left: -35px;
            visibility: visible;
            font-size: 16px;
        }
    }
    .share-span-1{
        width: 40px;
        height: 40px;
        border-radius: 999em;
        padding-top: 11px;
        visibility: hidden;
        font-size: 16px;
    }
    .share-span-2{
        width: 40px;
        height: 40px;
        margin-left: -38px;
        margin-top: 7px;

    }
}

.page-loader {
    position: absolute;
    z-index: 10000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    .MuiCircularProgress-root {
      position: absolute;
      z-index: 1000;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .MuiCircularProgress-colorPrimary {
    color: #fff !important;
  }


  // Alt Text
  .ada-alttext{
    background-color: white;
    position: relative;
    border-radius: 50%;
    width: max-content;
    color: black;
    font-size: 18px;
    font-weight: 800;
    margin-right: 20px;
    display: grid;
    place-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }

.switch-modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    & > p{
        margin: 0;
        font-size: 12px;
        color: white;
        text-shadow: 2px 2px #000;
    }
}

.functions-keys{
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
}

.preview-off .content-preview{
    display: none !important;
  }