.viewController-slider{
  background-color: white;
  padding: 0 10px;
  display: none;
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  transform: translateX(-50%) translateY(3px);
  left: 50%;
  bottom: 100%;
}

.viewController{
    cursor: pointer;
    display: grid;
    place-items: center;
    background-color: white;
    position: relative;
    border-radius: 8px;
    width: max-content;
    margin-right: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    &:hover .viewController-slider,&:focus .viewController-slider,.viewController-slider:hover{
      display: block;
    }
}

.zoom-slider {
    min-height: 220px !important;
    padding: 0 !important;
    span[data-index="2"] {
      box-shadow: 0px 0px 0px 5px rgba(245, 0, 87, 1) !important;
    }
    .MuiSlider-thumb {
      box-shadow: 0px 0px 0px 8px rgba(245, 0, 87, 0.16) !important;
    }
    .MuiSlider-thumbColorSecondary{
      background-color: rgba(245, 0, 87, 1);
    }
    .MuiSlider-track{
      background-color: rgba(245, 0, 87, 1);
      border-color: transparent;
    }
    .MuiSlider-rail {
      width: 10% !important;
      // position: absolute;
      padding: 0px !important;
    }

    .MuiSlider-root {
      width: 10% !important;
      padding: 0px !important;
    }
}

.font-Size{
  &-1{
    & .tag-1{
      font-size: 12px !important;
      word-wrap: break-word;
    }
  }
  &-2{
    & .tag-1{
      font-size: 16px !important;
      word-wrap: break-word;
    }
  }
  &-3{
    & .tag-1{
      font-size: 20px !important;
      word-wrap: break-word;
    }
  }
  &-4{
    & .tag-1{
      font-size: 24px !important;
      word-wrap: break-word;
    }
  }
  &-5{
    & .tag-1{
      font-size: 28px !important;
      word-wrap: break-word;
    }
  }
}