.dialog-container{
    width: 60%;
    // min-height: 145px;
    margin: auto;
}

.share{
    margin: auto;
}

.title-width{
    display: flex;
    width: 100%;
}

.icon-list{
    display: flex;
    width: 100%;
    height: 100%;
}

.facebook-icon-color{
    height: 99px;
    width: 99px;
    border-radius: 50%;
    margin-left: 175px;
    background-color: #3c5a96;
    .fb-icon{
        padding-top: 32px;
        padding-left: 31px;
    }
}

.twitter-icon-color{
    height: 99px;
    width: 99px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #35a9dc;
    .twit-icon{
        padding-top: 33px;
        padding-left: 32px;
    }
}

.pinterest-icon-color{
    height: 99px;
    width: 99px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #c8222d;
    .pint-icon{
        padding-top: 32px;
        padding-left: 32px;
    }
}

.check-box-list{
    margin: 20px auto 25px;
    text-align: center;
    max-width: 480px;
}

.span1{
    width: 400px;
    font-weight: 400;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn-right {
    top: 0px !important;
    color: #8F0339;
}