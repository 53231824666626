$primary-color: #8f0339;

.pannellum-wrapper {
  display: flex;
  flex: 1 1;
  height: 100%;
  flex-direction: column;
  .pnlm-container {
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.92) !important;
  }
  .pnlm-panorama-info {
    display: none !important;
  }
  .btn-wrapper {
    margin-bottom: 20px;
  }
  .pnlm-hotspot-base {
    &.icon-left {
      background: url(../../assets/images/hotspot-icons/icon-left.svg) no-repeat
        center center #fff;
    }
    &.icon-right {
      background: url(../../assets/images/hotspot-icons/icon-right.svg)
        no-repeat center center #fff;
    }
    &.icon-up {
      background: url(../../assets/images/hotspot-icons/icon-up.svg) no-repeat
        center center #fff;
    }
    &.icon-down {
      background: url(../../assets/images/hotspot-icons/icon-down.svg) no-repeat
        center center #fff;
    }
    &.icon-tr {
      background: url(../../assets/images/hotspot-icons/icon-tr.svg) no-repeat
        center center #fff;
    }
    &.icon-bl {
      background: url(../../assets/images/hotspot-icons/icon-bl.svg) no-repeat
        center center #fff;
    }
    &.icon-audio {
      background: url(../../assets/images/hotspot-icons/icon-audio.svg)
        no-repeat center center #fff;
    }
    &.icon-image {
      background: url(../../assets/images/hotspot-icons/icon-image.svg)
        no-repeat center center #fff;
    }
    &.icon-video {
      background: url(../../assets/images/hotspot-icons/icon-video.svg)
        no-repeat center center #fff;
    }
    &.icon-info {
      background: url(../../assets/images/hotspot-icons/icon-comment.svg)
        no-repeat center center #fff;
    }
    &.icon-editRequest {
      // background: url(../../assets/images/edit-icon.png) no-repeat center center #333333;
      background-color: #333;
    }
    &.icon-commentRequest {
      background: url(../../assets/images/info_image.png) no-repeat center
        center #fff;
    }
    &.icon-pdf {
      background: url(../../assets/images/hotspot-icons/pdf-svg.svg) no-repeat
        center center #fff;
    }
    &.icon-url {
      background: url(../../assets/images/hotspot-icons/icon-link.svg) no-repeat
        center center #fff;
    }
  }
  .custom-hotspot {
    height: 48px;
    width: 48px;
    background: #ffffff;
    border-radius: 50%;
  }
  div.custom-tooltip span {
    width: auto;
    min-width: 170px;
    // margin-left: -19px !important;
    bottom: 33px;
    visibility: hidden;
    position: absolute;
    border-radius: 3px;
    transition: all 0.4s ease-in;
    background-color: #fff;
    color: #000;
    text-align: center;
    max-width: 400px;
    padding: 5px 10px;
    margin-left: -220px;
    cursor: default;
    font-size: 12px;
    .tooltip-title {
      text-align: left;
      margin: 0 0 6px 0;
    }
    .tooltip-desc {
      text-align: left;
      margin: 0 0 6px 0;
      font-size: 10px;
    }
    img {
      width: 120px;
      height: 80px;
    }
  }
  div.custom-tooltip:hover span,
  .custom-hotspot:focus span,
  .custom-hotspot:focus-within span {
    visibility: visible;
    transition: all 0.2s ease-in;
  }

  .custom-hotspot:focus {
    border: 1px solid #8f0339 !important;
    border-radius: 50%;
    outline: none;
  }

  div.custom-tooltip span:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    bottom: -20px;
    left: -10px;
    margin: 0 50%;
  }
}
.hot-spot-selected {
  border: 2px solid #98233c;
  border-radius: 50% !important;
}
.total-hotspot {
  height: 20px;
  width: 20px;
  // padding-left: 7px;
}

.add-hotspot-icon {
  .MuiSvgIcon-root {
    font-size: 18px;
    color: #8f0339;
    position: absolute;
  }
}

.delete-hotspot-icon {
  .MuiSvgIcon-root {
    font-size: 14px;
    color: #8f0339;
    position: absolute;
  }
}

.scene-info-icon {
  position: relative;
  .MuiSvgIcon-root {
    font-size: 25px;
    color: #8f0339;
    // padding-left: 7px;
  }
  &:hover {
    & .info-button {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s ease-in;
    }
  }
  & .info-button {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 2000;
    left: 110%;
    transition: all 0.4s ease-in;
    background-color: rgb(245, 240, 240);
    max-width: 500px;
    height: auto;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    text-align: left;
    box-shadow: 4px 6px 8px rgba(172, 169, 169, 0.6);
    & * {
      font-size: 16px;
      margin-bottom: 10px;
      color: black;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
    & div:last-of-type {
      margin-bottom: 0;
    }
    & a {
      margin-bottom: 0;
      & p {
        color: #8f0339;
        width: 440px;
        margin: 0;
      }
    }
    & .arrow-button {
      position: absolute;
      left: 0;
      top: 10px;
      fill: rgb(245, 240, 240);
      transform: translateX(-9px) rotateY(180deg) scale(1.4);
    }
  }
}

.editRequest-input {
  width: 200px;
  height: 60px;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 1);
  margin-bottom: 5px;
  outline: none;
  padding: 5px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.input-select-edit-request {
  font-size: 14px;
  height: 20px;
  outline: none;
  width: 100%;
  border: 1px rgba(0, 0, 0, 1) solid;
  margin-bottom: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.btn-edit-request {
  height: auto;
  padding: 5px 16px !important;
  line-height: 1 !important;
  font-size: 12px !important;
}

.icon-editRequest > span {
  margin-top: -175px !important;
}

.icon-commentRequest > span {
  margin-top: -25px !important;
}

.center-comment-selected {
  border: 2px solid #98233c;
  border-radius: 50% !important;
}

.tour-360-display {
  width: 100%;
  height: 100vh;
}

.data-preview {
  width: 1000px;
  & .PrivateNotchedOutline-legendLabelled-8 {
    display: none;
  }
}

.data-edit {
  width: 400px;
  & .PrivateNotchedOutline-legendLabelled-8 {
    display: none;
  }
}

.no-max-width .MuiDialog-paperWidthSm {
  max-width: max-content;
}

.no-max-width.bg-white .MuiDialog-paperWidthSm {
  background-color: white;
}

#my-pdf {
  height: 700px !important;
  width: 100% !important;
  overflow-y: scroll;
}

.title-tooltip-white-input.max-width,
.action-select.max-width {
  width: 100% !important;
}

.text-area-modal.max-width {
  min-height: 27px;
  border: 1px solid grey;
  border-radius: 1px;
  outline: none;
  resize: none;
  padding: 4px;
  font-size: 14px;
  width: calc(100% - 8px) !important;
}

.tool-icon-btn.btn-download:hover {
  background-color: #ad0646 !important;
}

.bg-black {
  .MuiPaper-root {
    background-color: black;
  }
  .MuiDialogContent-root {
    padding: 8px 24px;
  }
}

// scrollbar css

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #858585;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

.preview-box {
  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}

.primary-btn {
  background: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 2px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #fff;
  padding: 4px 14px;
  font-size: 14px;
  text-transform: initial !important;

  &.outlined {
    background: #fff !important;
    border: 1px solid $primary-color !important;
    color: $primary-color;
  }

  &:hover {
    opacity: 0.92;
  }

  &.MuiButton-contained.Mui-disabled {
    opacity: 0.32;
    color: #fff;
  }
}

.fullEditor {
  color: white;
}
