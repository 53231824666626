.prog-image-wrapper, .image-wrapper {
    display: flex;
    flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
      .MuiLinearProgress-root {
          position: absolute;
          width: 50px;
      }
  }
  .MuiLinearProgress-colorSecondary {
      width: 100%;
  }
  .loading-image {
      filter: blur(1px);
      transition: opacity ease-in 1000ms;
      clipPath: inset(10);
      width: auto;
    height: auto;
    z-index: 1;
  }
  .image-loaded {
      width: 100%;
    height: 100%;
      transition: opacity ease-in 1000ms;
      border: 0;
  }
  .image-loader {
    align-items: center;
  }
  .progressive-canvas {
    transition: opacity 1s;
      width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    object-fit: cover;  
    &.loaded {
      opacity: 0;
      transition: opacity 1s;
    }
  }
  
  .full-absolute {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover
  }
  
  .progressive-img-load-canvas,
  .progressiveMedia-canvas {
      transition: opacity 1s
  }
  
  .full-loaded .progressive-img-load-canvas,
  .full-loaded .progressiveMedia-canvas {
      opacity: 0
  }
  
  .progressive-bg-image {
      background-position: 50%;
      background-size: cover;
      position: relative
  }
  
  .aspectRatioPlaceholder {
      position: relative;
      width: 100%;
      margin: 0 auto;
    display: block;
    height: 100%;
  }
  
  .progressiveMedia {
      background: rgba(0, 0, 0, .05)
  }
  
  .full-bg-image {
      width: 100%
  }
  
  .hidden {
      display: none;
      visibility: hidden
  }